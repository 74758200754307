@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;700&family=Open+Sans:wght@300;400;600&display=swap');

$secondary: #3a3a3a;

.full-width {
  width: 100%;
}

.text-xlg {
  font-size: 24px !important;
}

.text-lg {
  font-size: 17px !important;
  font-weight: 500;
}

.text-md {
  font-size: 15px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-xxs {
  font-size: 10px !important;
}

.center-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.center-vertical {
  display: flex !important;
  align-items: center !important;
  // justify-content: center !important;
}

.clickable {
  cursor: pointer;
}

.disabled:hover {
  cursor: default;
  background-color: black;
}

.disabled {
  opacity: 0.7;
  cursor: default !important;
}

.btn-outline {
  user-select: none;
  border: solid 1px black;
  transition: ease-out 0.25s;
  // border-radius: 100px;
}
.btn-outline:hover {
  cursor: pointer;
  // color: white;
  background: rgb(239, 239, 239);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
.btn-outline.selected {
  background: black;
  color: white;
}
.btn-outline.selected:hover {
  background: $secondary;
}

.hover-dark:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.hover-underline:hover {
  text-decoration: underline;
  color: $secondary;
}

.tab {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  background: white;
  margin-left: auto;
  margin-right: auto;
}

$SERIF: 'Noto Serif JP';

.serif {
  font-family: $SERIF, serif;
  font-weight: 400 !important;
}

.serif-light {
  font-family: $SERIF, serif;
  font-weight: 200 !important;
}

.serif-bold {
  font-family: $SERIF, serif;
  font-weight: 700 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}

h5 {
  color: #808080 !important;
  font-size: 9px;
  letter-spacing: 1px;
  font-family: 'Open Sans', sans-serif;
  user-select: none;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.modal-content {
  border-radius: 0px;
  padding: 10px;
}
